"use client";
import { Suspense, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { usePathname, useRouter } from "next/navigation";
import { Container, Box } from "@mui/material";

import { useStore, observer } from "../../src/service/mobx";

const Agent = dynamic(() => import("../../src/component/Agent/root"));
const PaneRight = dynamic(() => import("../../src/component/PaneRight"));
const Slide = dynamic(() => import("@mui/material/Slide"));
const Fade = dynamic(() => import("@mui/material/Fade"));

function Layout({ children }) {
  const [transitioning, setTransition] = useState(false);
  const { device, layout, reader } = useStore();
  const pathname = usePathname();
  const transitionTimingFunction = layout.mountContent
    ? "var(--motion-easing-emphasizedDecelerate) !important"
    : "var(--motion-easing-emphasizedAccelerate) !important";
  const twoColumns =
    (layout.mountAgent && layout.mountContent) || transitioning;

  // prevent page from scroll when agent unmounted, keeping agent scroll spot
  useEffect(() => {
    if (pathname !== "/" && layout.mountAgent === false) {
      document.body.style.overflowY = "hidden";

      return () => document.body.style.removeProperty("overflow-y");
    }
  }, [pathname, layout.mountAgent]);

  useEffect(() => {
    // if someone quickly navigates and interrupts a transition, then
    // always start transition === false, for fresh state
    setTransition(false);
  }, [pathname]);

  return (
    <Container
      disableGutters
      maxWidth={
        reader.whiteLabelled ? "large" : twoColumns ? false : layout.maxWidth
      }
      sx={{
        zIndex: device.isPhone ? undefined : "var(--zIndex-drawer)",
        display:
          reader.whiteLabelled === false && pathname !== "/"
            ? "grid"
            : undefined,
        transition: "opacity 1s",
        opacity: layout.checkedWhiteLabel ? 1 : 0,
        px: twoColumns
          ? { compact: 0, expanded: 2, large: 3 }
          : { compact: 0, expanded: 3, large: 0 },
        gap: twoColumns ? { compact: 2, expanded: 4, large: 6 } : undefined,
        gridTemplateColumns: `${
          twoColumns && device.isNotPhone ? "minmax(0, 38vw)" : "1fr"
        } ${twoColumns && device.isNotPhone ? "minmax(0, 62vw)" : "0fr"}`
      }}
    >
      {reader.whiteLabelled ? (
        <Box
          width="100%"
          minHeight="100vh"
          p={{ compact: 2, expanded: 3 }}
          bgcolor="var(--surface-container-lowest)"
        >
          {children}
        </Box>
      ) : (
        <>
          {pathname === "/" ? null : (
            <Suspense
              fallback={
                <Box display="flex" flexDirection="column" minHeight="100vh" />
              }
            >
              <Agent />
            </Suspense>
          )}
          {device.isPhone || pathname === "/" ? (
            children
          ) : (
            <Suspense fallback={<div />}>
              <Slide
                unmountOnExit
                direction="left"
                timeout={layout.transitionDuration}
                in={layout.mountContent && device.isPhone === false}
              >
                <Box
                  width="100%"
                  maxWidth="62vw"
                  zIndex={1}
                  display="flex"
                  sx={{ transitionTimingFunction }}
                >
                  <Fade
                    in={layout.mountContent && device.isPhone === false}
                    timeout={layout.transitionDuration}
                    onEnter={
                      device.isPhone ? undefined : () => setTransition(true)
                    }
                    onExited={
                      device.isPhone
                        ? undefined
                        : () =>
                            setTimeout(
                              setTransition,
                              layout.transitionDuration,
                              false
                            )
                    }
                  >
                    <Box width="100%" sx={{ transitionTimingFunction }}>
                      <Suspense>
                        <PaneRight>{children}</PaneRight>
                      </Suspense>
                    </Box>
                  </Fade>
                </Box>
              </Slide>
            </Suspense>
          )}
        </>
      )}
    </Container>
  );
}

export default observer(Layout);

export function MountAsBottomSheet({ children, isWhiteLabelled = false }) {
  const { device, bottomSheet } = useStore();
  const router = useRouter();

  useEffect(() => {
    if (device.isPhone && isWhiteLabelled === false) {
      if (bottomSheet.peek) {
        setTimeout(bottomSheet.set.expanded, 200, true);
      }

      bottomSheet.set.peek(true);
      bottomSheet.set.sx({ height: "95vh" });
      bottomSheet.set.Component(() => children);
      bottomSheet.set.open(true);
    }
  }, [bottomSheet, router, isWhiteLabelled, device.isPhone, children]);

  return device.isPhone && isWhiteLabelled === false ? null : children;
}
